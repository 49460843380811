<style lang="scss" scoped>
.main {
	height: calc(94vh);
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	background-color: $background_color2;
	margin-bottom: 0.6667rem;
}

::v-deep .van-swipe-item {
	img {
		width: 100%;
	}
}

.my_swipe {
	height: 3.5rem;
	position: relative;
}

::v-deep .van-nav-bar__content {
	background-color: $color_main;

	.van-nav-bar__title {
		font-size: 0.32rem;
	}

	.van-nav-bar__title,
	.van-nav-bar__text,
	.van-icon-arrow-left {
		color: $color_1;
	}
}

.recommend_word {
	font-size: 0.32rem;
	text-align: center;
	margin: 0.0667rem 0;
}

.content {
	flex: 1;
	margin-top: 0.1rem;
	.select_top{
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: 0.74rem;
		padding: 0 0.4rem;
		box-sizing: border-box;
		font-size: 0.28rem;
		border-bottom: 1px solid #ECF1F5;
		.select_title{
			font-weight: 700;
		}
		.select_info{
			color: $font_color_info;
			font-size: 0.2rem;
		}
	}
}

.recommend {
	// height: 6rem;
	padding: 0 0.4rem;
}

.recommend_good{
	width: 100%;
	height: 0.6rem;
	background: rgba(64, 158, 255,0.2);
	border-radius: 0.1rem;
	margin-top: 0.2rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 0.2rem;
	font-size: 0.24rem;
	box-sizing: border-box;
	.good_content{
		display: flex;
		justify-content: flex-start;
		align-items: center
	}
	.good_text{
		margin-left: 0.12rem;
	}
}
.recommend_box{
	margin-top: 0.2rem;
	padding: 0.2rem;
	background-color: #fff;
	border-radius: 0.12rem;
}

.recommend_item {
	display: flex;
	.img_box{
		width: 1.6rem;
		height: 1.72rem;
		border-radius: 0.12rem;
		overflow: hidden;
		position: relative;

		img{
			width: 100%;
			height: 100%;
		}
		.img_info{
			width: 100%;
			height: 0.36rem;
			background: rgba(0,0,0,0.4);
			font-size: 0.2rem;
			text-align: center;
			line-height: 0.36rem;
			color: $color_1;
			position: absolute;
			bottom: 0;
			left: 0;
		}
	}

	.productInfo {
		flex: 1;
		margin-left: 0.2rem;
		display: flex;
		justify-content: space-between;
		flex-direction: column;

		.name {
			width: 4.5rem;
			font-size: 0.28rem;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
			font-weight: 700;
		}


		.des_box {
			font-size: 0.2rem;
			color: rgba(0,0,0,0.65);
			flex: 1;
			
			.des_item{
				margin-bottom: 0.12rem;
				width: 100%;
				display: flex;	
				justify-content: flex-start;

				.desc_radius{
					width: 0.1rem;
					height: 0.1rem;
					border: 0.04rem solid #409EFF;	
					border-radius: 50%;
					margin:0.04rem 0.12rem 0 0;
				}
				.des_text{
					text-overflow: ellipsis;
					overflow: hidden;
					white-space: nowrap;
			
				}
				.w_26{
					width: 2.6rem;
				}
				.w_4{
					width: 4rem;
				}
			}
		}
		.price {
				display: flex;
				align-items: baseline;
				font-size: 0.2rem;
				color: $color_active_4;
				line-height: 0.32rem;
		
				.price_info{
					color: $font_color_info;
					margin-left: 0.06rem;
				}
				.price_big{
					font-size: 0.36rem;
					font-weight: 700;
				}
			}
	}
}
.price_goods{
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top:0.28rem ;
}
.flex_between{
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
}
.flex_align_end{
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	width: 100%;
}

.provice {
	font-size: 0.16rem;
	text-align: center;
	color: rgba(0,0,0,0.65);
	// font-weight: bold;
	transform: scale(0.9);
	height: 0.4rem;
	padding: 0.4rem;
}

.fix {
	position: absolute;
	z-index: 100;
	top: 0;
	left: 50%;
	transform: translateX(-50%);
}

//    主标题
.mainTitle {
	color: #ffffff;
	// color: #000;
	font-size: 24px;
	font-weight: 700;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 50px;
	margin-bottom: 20px;
}

//    副标题
.subTitle {
	display: flex;
	justify-content: center;
	align-items: center;

	.box {
		width: 4rem;
		height: 0.3333rem;
		display: flex;
		border-radius: 2px;
		justify-content: center;
		align-items: center;

		.word {
			width: 90%;
			background-color: #ffcc70;
			font-size: 12px;
			font-weight: 700;
			text-align: center;
			padding: 5px 8px;
			box-sizing: border-box;
			border-radius: 5px;
		}
	}
}
.title_name{
	color: $font_color_main;
}
.fx_1{
	flex: 1;
}
.buy_btn{
	width: 1.2rem;
	height: 0.4rem;
	background: #409EFF;
	border-radius: 0.2rem;
	font-size: 0.2rem;
	line-height: 0.4rem;
}
</style>

<template>
	<div class="main">
		<top-nav bgColor="#F6F6FB" :showBack="false"  v-if="cpsInfo"><span class="title_name">{{ cpsInfo.indexName }}</span> </top-nav>
		<van-swipe class="my_swipe" :autoplay="3000" indicator-color="white">
			<van-swipe-item v-for="(item, index) in cpsInfo.pictures" :key="index">
				<span @click="goTolink(index)"><img :src="item" alt="" /></span>
				<!-- <div class="fix">
					<div class="mainTitle">{{ cpsInfo.mainTitle }}</div>
					<div class="subTitle">
						<div class="box">
							<div class="word">{{ cpsInfo.subtitle }}</div>
						</div>
					</div>
				</div> -->
			</van-swipe-item>
		</van-swipe>
		<div class="content">
			<div class="select_top">
				<div class="select_title">为您精选</div>
				<div class="select_info">{{ this.cpsInfo.mainTitle }}</div>
			</div>
			<div class="recommend">
				<div v-for="item in $store.state.productList.filter((v,i)=>i<3)" :key="item.productId" class="recommend_box">
					<div class="recommend_item"  v-if="item.productId" >
						<div class="img_box">
							<img :src="item.proPicUrlIndexSmall"/>
							<div class="img_info" v-if="item.productAdv">{{ item.productAdv }}</div>
						</div>
						<div class="productInfo">
							<p class="name" :title="item.name">{{ item.name }}</p>
							<div :class="['des_box',{'flex_align_end':item.productContext}]">
								<div class="des_item_box">
									<div class="des_item" v-if="item.featureOne">
										<div class="desc_radius"></div>
										<div :class="['des_text',item.productContext?'w_26':'w_4']">
											<div>{{ item.featureOne }}</div>
										</div>
									</div>
									<div class="des_item" v-if="item.featureTwo">
										<div class="desc_radius"></div>
										<div :class="['des_text',item.productContext?'w_26':'w_4']">
											<div>{{ item.featureTwo }}</div>
										</div>
									</div>
									<div class="des_item" v-if="item.featureThree">
										<div class="desc_radius"></div>
										<div :class="['des_text',item.productContext?'w_26':'w_4']">
											<div>{{ item.featureThree }}</div>
										</div>
									</div>
								</div>
								<div class="price" v-if="item.productContext"><span>￥</span><span class="price_big">{{ item.originPrice.toFixed(2) }}</span><span class="price_info">起</span></div>
							</div>
							<div class="price_goods" v-if="!item.productContext">
								<div class="price" ><span>￥</span><span class="price_big">{{ item.originPrice.toFixed(2) }}</span><span class="price_info">起</span></div>
								<van-button class="buy_btn"  type="info" color="#409EFF" size="mini" @click="gotoDeatils(item)">立即投保</van-button>
							</div>
						</div>
					</div>
					<div class="recommend_good" v-if="item.productContext">
						<div class="good_content">
							<van-icon name="good-job" color="#409EFF" size="0.4rem"/>
							<div class="good_text">{{ item.productContext }}</div>
						</div>
						<van-button class="buy_btn"  type="info" color="#409EFF" size="mini" @click="gotoDeatils(item)">立即投保</van-button>
					</div>
					
				</div>
			</div>
		</div>
		<p class="provice">本页面保险产品销售、理赔等服务均由北京赛福哈博保险经纪提供</p>
	</div>
</template>

<script>
import { NavBar, Swipe, SwipeItem, Image as VanImage, Icon,Button } from 'vant';
import { cpsLoginCode } from '@/request/cps';
export default {
	name: 'cpsHome', // cps首页
	components: {
		[NavBar.name]: NavBar,
		[Swipe.name]: Swipe,
		[SwipeItem.name]: SwipeItem,
		[VanImage.name]: VanImage,
		[Icon.name]: Icon,
		[Button.name]: Button,

	},
	created() {
		this.getQuery();
		this.loginCode();
	},
	data() {
		return {
			cpsInfo: {},
			productsList: [],
		};
	},
	methods: {
		// 跳转链接
		goTolink(index) {
			if (this.cpsInfo.href && this.cpsInfo.href[index] == '~*~*') {
				return;
			}
			window.open(this.cpsInfo.href[index]);
		},

		//去详情页面
		gotoDeatils(item) {
			// if (item.name.includes('培训机构责任险')) {
			if (item?.versions === 1 && item?.insuranceCode === 2) {
				this.$router.push({ name: 'cpsLiProduct', query: { product: item.proId } });
				return;
			} else if (item.name.includes('“耀守护”培训机构责任险')) {
				let env = this.getEnv();
				let product;
				if (env === 'dev') {
					product = { product: `56123,71815` };
				} else if (env === 'test') {
					product = { product: `57925,31871` };
				} else if (env === 'pro') {
					product = { product: `56123,71815` };
				}
				this.$store.commit('set_cps_productId', product);
				this.$router.push({ name: 'cps_jiaoPeiJiGou_index', query: product });
				return;
			} else if (item.name.includes('国任公众')) {
				this.$store.commit('set_cps_productId', item.productId);
				this.$router.push({ name: 'cpsGuoRen_index', query: { productId: item.productId } });
				return;
			} else if (item.name.includes('马术场地责任')) {
				this.$store.commit('set_cps_productId', item.productId);
				this.$router.push({ name: 'cps_msDuty_index', query: { productId: item.productId } });
				return;
			}
			this.$router.push(`/cps/cpsProductDetail?productId=${item.productId}`);
		},
		getEnv() {
			let env = '';
			let devList = ['192.168.', '127.0.0.', 'localhost'];
			let testStr = 'testh5';
			if (devList.some(item => location.hostname.includes(item))) {
				env = 'dev';
			} else if (location.hostname.includes(testStr)) {
				env = 'test';
			} else {
				env = 'pro';
			}
			return env;
		},
		// 获取地址code
		getQuery() {
			let query = this.$route.query;
			if (query) {
				this.code = query.code;
				this.$store.commit('set_cps_code', { code: query.code });
			}
		},
		// 登录
		async loginCode() {
			try {
				const res = await cpsLoginCode(this.code);
				this.cpsInfo = res.userInfo;
				this.cpsInfo.pictures = this.cpsInfo.pictures.split(' ');
				this.cpsInfo.href = this.cpsInfo.href.split(' ');
				this.$store.commit('set_cps_brindInfo', res.userInfo);
				this.$store.commit('set_productList', res.userInfo.product);
				this.$base.setCpsLogo()

			} catch (error) {}
		},
	},
};
</script>
